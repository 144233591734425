<template>
  <div class="offer-tasks">
    Aufgaben
  </div>
</template>

<script>

import OfferHeaderNavigation from "../../../components/offers-details/HeaderNavigation"

export default {
  name: "OfferTasks",
  components: {
    OfferHeaderNavigation
  },
  data() {
    return {
      order: null,
    }
  },
  computed: {

  },
  mounted() {
  },
  methods: {

  }
}
</script>

<style lang="scss">

</style>
