<template>
  <div class="offer-details mb-4">
    <vs-row class="pb-4" v-if="order">
      <vs-col vs-justify="left" vs-align="center" vs-w="7">
        <div class="pb-1">
          <router-link to="/" >
            <feather-icon icon="HomeIcon" svgClasses="h-5 w-5 mb-1 stroke-current text-primary" />
          </router-link>
          <span class="breadcrumb-separator mx-2"><feather-icon icon="ChevronsRightIcon" svgClasses="w-4 h-4" /></span>
          <span class="offer">Angebot #{{order.invoice_number}} </span>
          <h2 class="offer-title pb-1">- {{order.title}}</h2>
        </div>
        <router-link
            :to="{name:'customers-detail', params: { id: order.client.id }}"
            class="NavigationItem__router-link company-name">{{order.client.company_name}}
        </router-link>
      </vs-col>
      <vs-col vs-justify="right" class="flex justify-end dropdown-buttons" vs-align="center"  vs-w="5">

        <vs-dropdown class="mr-6 status-btn mb-2" :class="statusButtonBgColor" >
          <a class="a-icon" href="#">
            Status:  {{order.order_status.name}}
            <vs-icon class="" icon="expand_more"></vs-icon>
          </a>

          <vs-dropdown-menu>
            <vs-dropdown-item  v-for="item in orderStatusList" @click="onChangeStatus(item)" v-bind:class="item.id === order.order_status.id ? 'is-active' : ''">
              {{item.name}}
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>


        <vs-dropdown class="actions-btn mb-2">
          <a class="a-icon" href="#">
            Aktionen
            <vs-icon class="" icon="expand_more"></vs-icon>
          </a>
          <vs-dropdown-menu style="width: 7%">
            <vs-dropdown-item>
              Option 1
            </vs-dropdown-item>
            <vs-dropdown-item>
              Option 2
            </vs-dropdown-item>
            <vs-dropdown-item divider>
              Option 3
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </vs-col>
    </vs-row>
    <vs-row class="tabs">
      <ul class="flex">
        <li class="mr-6" v-bind:class="[$route.name === 'offers-view' ? 'is-active' : '']">
          <router-link
              :to="{name:'offers-view'}"
              class="NavigationItem__router-link">Vorschau
          </router-link>
        </li>
        <li class="mr-6" v-bind:class="[$route.name === 'offers-detail' ? 'is-active' : '']">
          <router-link
              :to="{name:'offers-detail'}"
              class="NavigationItem__router-link">Position bearbeiten
          </router-link>
        </li>
        <li class="mr-6" v-bind:class="[$route.name === 'offers-task-allocation' ? 'is-active' : '']" v-if="isOfferAccepted">
          <router-link
              :to="{name:'offers-task-allocation'}"
              class="NavigationItem__router-link">Aufgabenzuweisung
          </router-link>
        </li>
        <li class="mr-6" v-bind:class="[$route.name === 'offer-sprint-planer' || $route.name === 'offer-sprint-planer-detail'  ? 'is-active' : '']">
            <router-link
                    :to="{name:'offer-sprint-planer'}"
                    class="NavigationItem__router-link">Sprint-Planung
            </router-link>
        </li>
        <li class="mr-6" v-bind:class="[$route.meta.parent === 'offers-accounting' || $route.meta.parent === 'offers-accounting-assignment'
         || $route.meta.parent === 'offers-accounting-create-final-invoice' ? 'is-active' : '']" v-if="isOfferAccepted">
          <router-link
              :to="{name:'offers-accounting-already-billed'}"
              class="NavigationItem__router-link">Abrechnung
          </router-link>
        </li>
        <!--        <li class="mr-4" v-bind:class="[$route.name === 'offers-tasks' ? 'is-active' : '']">-->
        <!--          <router-link-->
        <!--              :to="{name:'offers-tasks'}"-->
        <!--              class="NavigationItem__router-link">Aufgaben-->
        <!--          </router-link>-->
        <!--        </li>-->
        <li class="mr-4" v-bind:class="[$route.name === 'offers-planned-sales' ? 'is-active' : '']" v-if="isOfferAccepted">
          <router-link
                  :to="{name:'offers-planned-sales'}"
                  class="NavigationItem__router-link">Planumsätze
          </router-link>
        </li>
        <li class="mr-6" v-bind:class="[$route.name === 'offers-processing-history' ? 'is-active' : '']">
          <router-link
              :to="{name:'offers-processing-history'}"
              class="NavigationItem__router-link">Bearbeitungsverlauf
          </router-link>
        </li>
      </ul>
    </vs-row>
  </div>
</template>

<script>
import ApiService from "../../api";

export default {
  name: "OfferHeaderNavigation",
  components: {
  },
  data() {
    return {
      order: null,
      orderStatusList: [],

    }
  },
  created() {
    this.fetchData()
  },
  computed: {
    statusButtonBgColor() {
      if (this.order.order_status.id === 16 || this.order.order_status.id === 18) {
        return 'success'
      } else if (this.order.order_status.id === 15 ) {
        return 'orange'
      } else if (this.order.order_status.id === 13 || this.order.order_status.id === 14){
        return 'gray'
      }else if (this.order.order_status.id === 17){
        return 'red'
      } else {
        return 'gray'
      }
    },
    isOfferAccepted(){
      if (this.order && (this.order.status === 16 || this.order.status === 18 || this.order.status === 15 || this.order.status === 19) ) {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    fetchData() {
      const id = this.$route.params.id;
      this.$vs.loading()
      ApiService.get(`orders/${id}`).then(response => {
        this.order = response.data.result;
        this.fetchDataStatus()
        this.$vs.loading.close()
      }).catch(response => {
      })
    },
    fetchDataStatus() {
      const id = this.$route.params.id;
      this.$vs.loading()
      this.getStatusName = this.order.order_status.name
      ApiService.get(`order_status?filter[invoice_type]=offer`).then(response => {
        this.orderStatusList = response.data.result;
        this.$vs.loading.close()
      }).catch(response => {
      })
    },

    onChangeStatus(item) {
      const id = this.$route.params.id;
      this.order.status = item.id;

      const postData = {
        status: item.id,
        generate_pdf: false,
      };

      this.$vs.loading();

      ApiService.put(`orders/${id}`, postData).then(response => {
        if (response.status === 200) {
          this.$vs.loading.close();
          this.fetchData()
          this.$vs.notify({
            title: 'Erfolgreich',
            text: 'Der Status wurde erfolgreich aktuallisiert',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'success'
          });
        }
      }).catch(response => {

        this.$vs.loading.close();
        this.$vs.notify({
          title: 'Fehler',
          text: response.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      });
    }
  }
}
</script>

<style lang="scss">
.is-active {
  font-weight:bold!important;
}
.offer-details {
  .offer-title {
    font-size: 20px;
    color: #636363;
    display: inline;
  }
  .company-name {
    border-bottom: 1px #2c2c2c solid;
    color: #626262;
  }
  ul {
    li {
      &.is-active {
        font-weight: bold;
        border-bottom: 1px #e24733 solid;
        a {
          color: #e24733;
        }
      }
      a {
        font-size: 14px;
        color: #626262;
      }
    }
  }

  .dropdown-buttons {
    .status-btn {
      padding: 10px 15px;
      background-color: #ec7b24;
      border-radius: 5px;

      a {
        color: #ffffff !important;
        display: flex;
        .vs-icon {
          padding-left: 3px;
          font-size: 1.3em;
        }
      }
      &:hover {
        cursor: pointer;
      }
      &.gray {
        background-color: #636363;
      }
      &.success {
        background-color: #71c73f;
      }
      &.orange {
        background-color: #ec7b24;
      }
      &.red {
        background-color: #ea3540;
      }
    }
    .actions-btn {
      padding: 10px 15px;
      background-color: #ec7b24;
      border-radius: 5px;
      a {
        color: #ffffff !important;
        display: flex;
        .vs-icon {
          padding-left: 3px;
          font-size: 1.3em;
        }
      }
    }
  }
  .offer {
    font-size: 20px;
    color: #636363;
  }
  .tabs {
    background-color: #ffffff;
    border-radius: .5rem;
    padding: .8rem 1rem;
    -webkit-box-shadow: 0 4px 25px 0 rgba(#333, 0.2%);
    box-shadow: 0 4px 25px 0 rgba(#333, 0.2)
  }
}
</style>
